import { ApolloClient } from "@apollo/client";
import { GetDeliveryProfilesQuery, GetSellingPlanGroupsQuery } from "@smartrr/shared/shopifyGraphQL/api";
import { paginatedQueryShopifyDeliveryProfiles } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

type SellingPlanGroupNode = DeepExtractTypeSkipArrays<
  GetSellingPlanGroupsQuery,
  ["sellingPlanGroups", "edges", "node"]
>;

type DeliveryProfileNode = DeepExtractTypeSkipArrays<
  GetDeliveryProfilesQuery,
  ["deliveryProfiles", "edges", "node"]
>;

export const checkProfileConflicts = async (
  apolloClient: ApolloClient<object>,
  deliveryProfileId: string,
  selectedSellingPlanGroupIds: string[],
  sellingPlanGroups: SellingPlanGroupNode[]
): Promise<{ conflictingProfiles: DeliveryProfileNode[]; conflictingGroups: SellingPlanGroupNode[] }> => {
  const allProfiles = await paginatedQueryShopifyDeliveryProfiles(apolloClient);
  const subscriptionProfiles = allProfiles.filter(profile => !!profile.sellingPlanGroups.edges.length);
  const conflictingPrograms: Record<string, SellingPlanGroupNode> = {};

  const conflictingProfiles = subscriptionProfiles.filter(profile => {
    if (profile.id === ensureShopifyGid("DeliveryProfile", deliveryProfileId)) {
      return false;
    }

    const conflictsList = profile.sellingPlanGroups.edges.filter(otherGroup => {
      if (!selectedSellingPlanGroupIds.includes(otherGroup.node.id)) {
        return false;
      }

      const group = sellingPlanGroups.find(group => group.id === otherGroup.node.id);
      if (group) {
        conflictingPrograms[group.id] = group;
      }
      return true;
    });

    return conflictsList.length > 0;
  });

  return {
    conflictingProfiles,
    conflictingGroups: Object.values(conflictingPrograms),
  };
};
