import { Modal, ResourceItem, ResourceList, Thumbnail } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { uniq } from "lodash";
import React, { useEffect, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  selectedProductIds: string[];
  open: boolean;
  onClose(): void;
  onSave(selectedProductIds: string[]): void;
}

export function ProductModal({ selectedProductIds, open, onClose, onSave }: Props): JSX.Element {
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedItems(selectedProductIds);
    }
  }, [open]);

  return (
    <Modal
      title="Edit profile items"
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Done",
        onAction: () => onSave(selectedItems),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <ResourceList
          selectable
          showHeader={false}
          selectedItems={selectedItems}
          onSelectionChange={selected => setSelectedItems(selected as string[])}
          items={purchasables}
          renderItem={item => (
            <ResourceItem
              id={item.shopifyId!}
              onClick={() => {
                if (selectedItems.includes(item.shopifyId!)) {
                  setSelectedItems(selectedItems.filter(i => i !== item.shopifyId!));
                } else {
                  setSelectedItems(uniq(selectedItems.concat(item.shopifyId!)));
                }
              }}
            >
              <Box gap={1} alignItems="center">
                {!!item.purchasableImages?.length && (
                  <Thumbnail size="small" source={item.purchasableImages?.[0]} alt={item.purchasableName} />
                )}
                <span>{item.purchasableName}</span>
              </Box>
            </ResourceItem>
          )}
        />
      </Modal.Section>
    </Modal>
  );
}
