import { Button, Icon, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { LocationMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { CountryCode, LocationFragmentFragment } from "@smartrr/shared/shopifyGraphQL/api";
import { FullDeliveryProfileLocationGroup } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { groupBy } from "lodash";
import React, { useState } from "react";

import { CreateZoneModal } from "./modals/CreateZone/CreateZoneModal";
import { Zone } from "./Zone";
import { IShopifyCountryServiceCountry } from "../../types";
import { decodeZoneId } from "../../utils/zoneUtils";

interface Props {
  index: number;
  profileLocationGroup: FullDeliveryProfileLocationGroup;
  countries: IShopifyCountryServiceCountry[];
  onUpdate(profileLocationGroup: FullDeliveryProfileLocationGroup): void;
  onDelete(): void;
}

export function LocationGroup({ profileLocationGroup, countries, onUpdate, onDelete }: Props): JSX.Element {
  const [showCreateZoneModal, setShowCreateZoneModal] = useState(false);

  const onCreateZone = (name: string, selectedZones: "restOfWorld" | { id: string; label: string }[]) => {
    if (selectedZones === "restOfWorld") {
      onUpdate({
        ...profileLocationGroup,
        locationGroupZones: profileLocationGroup.locationGroupZones.concat({
          methodDefinitionCounts: {
            participantDefinitionsCount: 0,
            rateDefinitionsCount: 0,
          },
          methodDefinitions: [],
          zone: {
            id: "",
            name,
            countries: [
              {
                code: {
                  restOfWorld: true,
                  countryCode: null,
                },
                id: "",
                name: "Rest of World",
                provinces: [],
              },
            ],
          },
        }),
      });
      setShowCreateZoneModal(false);
      return;
    }

    const selectedIdsByCountryCode = groupBy(selectedZones, ({ id }) => {
      const decodedId = decodeZoneId(id);
      return decodedId.length === 1 ? null : decodedId[1];
    });

    const updatedProfileLocationGroup: FullDeliveryProfileLocationGroup = {
      ...profileLocationGroup,
      locationGroupZones: profileLocationGroup.locationGroupZones.concat({
        methodDefinitionCounts: {
          participantDefinitionsCount: 0,
          rateDefinitionsCount: 0,
        },
        methodDefinitions: [],
        zone: {
          id: "",
          name,
          countries: Object.keys(selectedIdsByCountryCode)
            .filter(code => code !== "null")
            .map(countryCode => {
              const provinces = selectedIdsByCountryCode[countryCode].filter(
                ({ id }) => decodeZoneId(id).length === 3
              );

              return {
                id: "",
                name: countries.find(c => c.code === countryCode)!.name,
                code: {
                  countryCode: countryCode as CountryCode,
                  restOfWorld: false,
                },
                provinces: provinces.map(({ id: encodedId, label }) => ({
                  id: "",
                  name: label,
                  code: decodeZoneId(encodedId)[2]!,
                })),
              };
            }),
        },
      }),
    };

    onUpdate(updatedProfileLocationGroup);
    setShowCreateZoneModal(false);
  };

  const onRemoveLocation = (locationToRemove: LocationFragmentFragment) => {
    onUpdate({
      ...profileLocationGroup,
      locationGroup: {
        ...profileLocationGroup.locationGroup,
        locations: profileLocationGroup.locationGroup.locations.filter(loc => loc.id !== locationToRemove.id),
      },
    });
  };

  return (
    <LegacyCard>
      <LegacyCard.Section
        title={
          <Text variant="headingMd" as="h2">
            Shipping from
          </Text>
        }
        actions={[
          {
            content: "Remove rates",
            destructive: true,
            onAction: onDelete,
          },
        ]}
      >
        <Box mt={1} vertical gap={1}>
          {!profileLocationGroup.locationGroup.locations.length && (
            <div>
              This group contains a location that was added but cannot be identified. This can occur if the
              location is managed by a third-party app, such as ShipBob. We are currently working to find a
              resolution for this issue.
            </div>
          )}
          {profileLocationGroup.locationGroup.locations.map(location => (
            <Box key={location.id} justifyContent="space-between">
              <LegacyStack>
                <LegacyStack.Item>
                  <Icon source={LocationMajor} />
                </LegacyStack.Item>
                <LegacyStack.Item>{location.name}</LegacyStack.Item>
              </LegacyStack>
              <LegacyStack.Item>
                {profileLocationGroup.locationGroup.locations.length > 1 && (
                  <Button plain onClick={() => onRemoveLocation(location)}>
                    Remove
                  </Button>
                )}
              </LegacyStack.Item>
            </Box>
          ))}
        </Box>
      </LegacyCard.Section>
      <LegacyCard.Section
        title={
          <Text variant="headingMd" as="h2">
            Shipping to
          </Text>
        }
        actions={[
          {
            content: "Add zone",
            onAction: () => setShowCreateZoneModal(true),
          },
        ]}
      >
        {profileLocationGroup.locationGroupZones.map((zone, index) => (
          <Zone
            key={index}
            index={index}
            zone={zone}
            countries={countries}
            onRemove={() => {
              onUpdate({
                ...profileLocationGroup,
                locationGroupZones: [
                  ...profileLocationGroup.locationGroupZones.slice(0, index),
                  ...profileLocationGroup.locationGroupZones.slice(index + 1),
                ],
              });
            }}
            onUpdate={updatedProfileLocationGroupZone => {
              onUpdate({
                ...profileLocationGroup,
                locationGroupZones: [
                  ...profileLocationGroup.locationGroupZones.slice(0, index),
                  updatedProfileLocationGroupZone,
                  ...profileLocationGroup.locationGroupZones.slice(index + 1),
                ],
              });
            }}
          />
        ))}
      </LegacyCard.Section>
      <CreateZoneModal
        countries={countries}
        open={showCreateZoneModal}
        onClose={() => setShowCreateZoneModal(false)}
        onSave={onCreateZone}
      />
    </LegacyCard>
  );
}
