import { Box } from "@smartrr/shared/components/primitives";
import { flatten, isEmpty, orderBy, uniq, uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import styled from "styled-components";

import { EncodedZoneId, IShopifyCountryServiceCountry, REST_OF_WORLD, Region } from "../../../../types";
import { decodeZoneId, useAllRegions } from "../../../../utils/zoneUtils";

const ZoneItem = styled.li<{ id: string }>`
  cursor: pointer;
  list-style: none;
  padding-left: ${props =>
    decodeZoneId(props.id).length === 3 ? 4 : decodeZoneId(props.id).length === 2 ? 2 : 0}rem;

  &:hover {
    background: #eee;
  }

  input[type="checkbox"] {
    height: 1.4rem;
    width: 1.4rem;
  }
`;

interface Props {
  countries: IShopifyCountryServiceCountry[];
  selectedIds: EncodedZoneId[];
  restOfWorldChecked: boolean;
  onChange(ids: EncodedZoneId[], restOfWorldChecked: boolean): void;
  searchQuery?: string;
}

export function RegionList({
  onChange,
  countries,
  restOfWorldChecked,
  selectedIds,
  searchQuery = "",
}: Props): JSX.Element {
  const [expandedCountries, setExpandedCountries] = useState<string[]>(
    uniq(selectedIds.filter(id => decodeZoneId(id).length === 3).map(id => decodeZoneId(id)[1]!))
  );
  const allRegions = useAllRegions(countries);

  const filteredItems = allRegions.filter(({ id, label }) => {
    const [, countryCode, zoneCode] = decodeZoneId(id);
    const isCountry = countryCode && !zoneCode;
    if (isCountry) {
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        getProvincesForCountryId(allRegions, countryCode!).filter(({ label }) =>
          label.toLowerCase().includes(searchQuery.toLowerCase())
        ).length
      );
    }
    return label.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const citiesByCountries = filteredItems.map(item => {
    const [, countryCode, zoneCode] = decodeZoneId(item.id);
    const isCountry = countryCode && !zoneCode;
    if (isCountry) {
      const provincesForCountryId = getProvincesForCountryId(allRegions, countryCode!).filter(item =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return isEmpty(provincesForCountryId)
        ? getProvincesForCountryId(allRegions, countryCode!)
        : provincesForCountryId;
    }
    return item;
  });

  const sortedItems = useMemo(
    () => uniqBy(orderBy([...filteredItems, ...flatten(citiesByCountries)], ["id"]), "id"),
    [filteredItems, citiesByCountries]
  );

  const onItemClick = (selectedItemId: string) => {
    if (selectedItemId === "restOfWorld") {
      if (restOfWorldChecked) {
        onChange(selectedIds, false);
      } else {
        onChange([], true);
      }
      return;
    }

    const itemIsChecked = selectedIds.find(id => id === selectedItemId);
    if (itemIsChecked) {
      onChange(
        selectedIds.filter(itemId => !itemId.includes(selectedItemId)),
        false
      );
    } else {
      onChange(
        uniq(
          selectedIds.concat(
            allRegions.filter(({ id: itemId }) => itemId.includes(selectedItemId)).map(({ id: itemId }) => itemId)
          )
        ),
        false
      );
    }
  };

  const toggleExpand = (id: string) => {
    const [, countryId] = decodeZoneId(id);
    if (expandedCountries.includes(countryId!)) {
      setExpandedCountries(expandedCountries.filter(id => id !== countryId));
    } else {
      setExpandedCountries(expandedCountries.concat(countryId!));
    }
  };
  const rows = sortedItems.filter(({ id }) => {
    const decodedId = decodeZoneId(id);
    return !(decodedId.length === 3 && !expandedCountries.includes(decodedId[1]!));
  });
  rows.unshift({
    id: REST_OF_WORLD,
    label: "Rest of world",
  });

  return (
    <FixedSizeList width="100%" height={400} itemSize={40} itemCount={rows.length}>
      {({ index, style }) => {
        const { id, label } = rows[index];
        return (
          <ZoneItem style={style} key={id} id={id} onClick={() => onItemClick(id)}>
            <Box alignItems="center" p={0.4} gap={1}>
              <input
                type="checkbox"
                checked={id === REST_OF_WORLD ? restOfWorldChecked : selectedIds.includes(id)}
                onChange={e => {
                  e.stopPropagation();
                  onItemClick(id);
                }}
              />
              <span>{label}</span>
              {isExpandable(id, allRegions) && (
                <span
                  style={{ marginLeft: "auto", marginRight: "1rem", cursor: "pointer" }}
                  onClick={e => {
                    e.stopPropagation();
                    toggleExpand(id);
                  }}
                >
                  {expandedCountries.includes(decodeZoneId(id)[1]!) ? "Collapse" : "Expand"}
                </span>
              )}
            </Box>
          </ZoneItem>
        );
      }}
    </FixedSizeList>
  );
}

function getProvincesForCountryId(allRegions: Region[], countryId: string) {
  return allRegions.filter(({ id }) => {
    const decodedId = decodeZoneId(id);
    return decodedId.length === 3 && decodedId[1] === countryId;
  });
}

function isExpandable(id: string, allRegions: Region[]) {
  return (
    decodeZoneId(id).length === 2 &&
    !!allRegions.filter(
      ({ id: regionId }) =>
        decodeZoneId(regionId).length === 3 && decodeZoneId(regionId)[1] === decodeZoneId(id)[1]
    ).length
  );
}
