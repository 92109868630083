import { Button, ChoiceList, FormLayout, Modal, Select, TextField } from "@shopify/polaris";
import { CurrencyCode, WeightUnit } from "@smartrr/shared/shopifyGraphQL/api";
import { getUnitWeightInputFromUnit } from "@smartrr/shared/utils/getWeithUnitInputFromUnit";
import React, { useEffect, useMemo, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  returnFilteredArrayIfItExists,
  validateIfFirstLowerThanSecondValue,
  validateIfNumberAboveZero,
} from "../../../utils/validationUtils";

export type ConditionType = "price" | "weight";
export interface Condition {
  ids?: string[]; // if conditions already exist in shopify, put ids here (so they can be deleted later and replaced with the new ones)
  type: ConditionType;
  min: number;
  max: number | null;
  unit?: WeightUnit;
}
export interface Rate {
  id: string | null;
  name: string;
  amount: number | null;
  currency: CurrencyCode | null;
  condition?: Condition;
}

const DEFAULT_MIN_WEIGHT = "0";
const DEFAULT_MAX_WEIGHT = "";
const DEFAULT_MIN_PRICE = "0";
const DEFAULT_MAX_PRICE = "";

export function RateModal({
  rate,
  show,
  onSave,
  onClose,
}: {
  rate?: Rate;
  show: boolean;
  onSave(
    id: string | null,
    name: string,
    amount: number,
    currencyCode: CurrencyCode,
    condition?: Condition
  ): void;
  onClose(): void;
}): JSX.Element {
  const shopCurrency = useSmartrrVendorSelector(state => state.shopifyStoreData.shopifyData?.currency);
  const shopCurrencyKey = useMemo(
    () => Object.keys(CurrencyCode).find(key => CurrencyCode[key] === shopCurrency),
    [shopCurrency]
  );

  const defaultCurrency = (shopCurrencyKey ?? Object.keys(CurrencyCode)[0]) as CurrencyCode;
  const [rateName, setRateName] = useState<string>(rate?.name || "");
  const [ratePrice, setRatePrice] = useState<string>(rate?.amount ? "" + rate.amount : "0");
  const [rateCurrency, setRateCurrency] = useState<CurrencyCode>(rate?.currency || defaultCurrency);

  const [showConditions, setShowConditions] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState<ConditionType>("weight");
  const [minWeight, setMinWeight] = useState(DEFAULT_MIN_WEIGHT);
  const [maxWeight, setMaxWeight] = useState(DEFAULT_MAX_WEIGHT);
  const [minPrice, setMinPrice] = useState(DEFAULT_MIN_PRICE);
  const [maxPrice, setMaxPrice] = useState(DEFAULT_MAX_PRICE);
  const [weightUnit, setWeightUnit] = useState(WeightUnit.Pounds);

  const unitWeight = useSmartrrVendorSelector(state => state.shopifyStoreData.shopifyData?.weight_unit);

  useEffect(() => {
    if (show && rate) {
      setRateName(rate.name);
      setRatePrice("" + rate.amount);
      setRateCurrency(Object.keys(CurrencyCode).find(c => c.toUpperCase() === rate.currency) as CurrencyCode);

      if (rate.condition) {
        setShowConditions(true);
        setWeightUnit(getUnitWeightInputFromUnit(unitWeight));
        if (rate.condition.type === "price") {
          setSelectedCondition("price");
          setMinPrice("" + rate.condition.min);
          setMaxPrice(rate.condition.max === null ? "" : "" + rate.condition.max);
        } else {
          setSelectedCondition("weight");
          setMinWeight("" + rate.condition.min);
          setMaxWeight(rate.condition.max === null ? "" : "" + rate.condition.max);
        }
      }
    }
  }, [show]);

  const onSaveClick = () => {
    if (
      validatePrice() ||
      validateMinWeight() ||
      validateMaxWeight() ||
      validateMinPrice() ||
      validateMaxPrice()
    ) {
      console.error("not a number");
      return;
    }

    const min = selectedCondition === "weight" ? minWeight : minPrice;
    const max = selectedCondition === "weight" ? maxWeight : maxPrice;

    const condition = {
      ids: rate?.condition?.ids,
      type: selectedCondition,
      min: Number(min) || 0,
      max: max === "" ? null : Number(max) || null,
      ...(selectedCondition === "weight" && { unit: weightUnit }),
    };

    onSave(
      rate?.id || null,
      rateName,
      Number(ratePrice),
      rateCurrency.toUpperCase() as CurrencyCode,
      showConditions ? condition : undefined
    );
    setRateName("");
    setRatePrice("0");
    setRateCurrency(defaultCurrency);
    setMinWeight(DEFAULT_MIN_WEIGHT);
    setMaxWeight(DEFAULT_MAX_WEIGHT);
    setMinPrice(DEFAULT_MIN_PRICE);
    setMaxPrice(DEFAULT_MAX_PRICE);
  };

  const currencyCodeOptions = Object.keys(CurrencyCode)
    .sort()
    .map(key => ({
      label: key.toUpperCase(),
      value: key,
    }));

  const validateMinWeight = () => {
    return returnFilteredArrayIfItExists([validateIfNumberAboveZero(minWeight, "Min weight")], item => !!item);
  };

  const validateMaxWeight = () => {
    return returnFilteredArrayIfItExists(
      [
        validateIfNumberAboveZero(maxWeight, "Max weight"),
        validateIfFirstLowerThanSecondValue(maxWeight, minWeight, "Max weight", "min weight"),
      ],
      item => !!item
    );
  };

  const validateMinPrice = () => {
    return returnFilteredArrayIfItExists([validateIfNumberAboveZero(minPrice, "Min price")], item => !!item);
  };

  const validateMaxPrice = () => {
    return returnFilteredArrayIfItExists(
      [
        validateIfNumberAboveZero(maxPrice, "Max price"),
        validateIfFirstLowerThanSecondValue(maxPrice, minPrice, "Max price", "min price"),
      ],
      item => !!item
    );
  };

  const validatePrice = () => {
    return returnFilteredArrayIfItExists([validateIfNumberAboveZero(ratePrice, "Price")], item => !!item);
  };

  const CurrencySelect = (
    <Select
      label="Currency"
      labelHidden
      value={rateCurrency}
      onChange={value => setRateCurrency(value as CurrencyCode)}
      options={currencyCodeOptions}
    />
  );

  return (
    <Modal
      open={show}
      onClose={onClose}
      title="Add rate"
      primaryAction={{
        content: "Save",
        onAction: onSaveClick,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField autoComplete="off" label="Name" type="text" onChange={setRateName} value={rateName} />
            <TextField
              autoComplete="off"
              label="Price"
              type="currency"
              inputMode="decimal"
              error={validatePrice()}
              onChange={value => setRatePrice(value)}
              value={"" + ratePrice}
              connectedRight={CurrencySelect}
            />
          </FormLayout.Group>
          <Button plain onClick={() => setShowConditions(show => !show)}>
            {showConditions ? "Remove" : "Add"} conditions
          </Button>
          {showConditions ? (
            <FormLayout.Group>
              <ChoiceList
                title=""
                titleHidden
                allowMultiple={false}
                selected={[selectedCondition]}
                onChange={value => setSelectedCondition(value[0] as ConditionType)}
                choices={[
                  {
                    label: "Based on item weight",
                    value: "weight",
                  },
                  {
                    label: "Based on order price",
                    value: "price",
                  },
                ]}
              />
            </FormLayout.Group>
          ) : null}
          {showConditions && selectedCondition === "weight" ? (
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                label="Minimum weight"
                inputMode="decimal"
                value={minWeight}
                error={validateMinWeight()}
                onChange={setMinWeight}
                suffix={unitWeight ?? "-"}
              />
              <TextField
                autoComplete="off"
                label="Maximum weight"
                placeholder="No limit"
                suffix={unitWeight ?? "-"}
                inputMode="decimal"
                value={maxWeight}
                error={validateMaxWeight()}
                onChange={setMaxWeight}
              />
            </FormLayout.Group>
          ) : null}
          {showConditions && selectedCondition === "price" ? (
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                prefix="$"
                label="Minimum price"
                value={minPrice}
                error={validateMinPrice()}
                onChange={setMinPrice}
                type="currency"
              />
              <TextField
                autoComplete="off"
                type="currency"
                prefix="$"
                label="Maximum price"
                value={maxPrice}
                error={validateMaxPrice()}
                placeholder="No limit"
                onChange={setMaxPrice}
              />
            </FormLayout.Group>
          ) : null}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
