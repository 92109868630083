import { ApolloClient } from "@apollo/client";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { DeliveryProfileInput } from "@smartrr/shared/shopifyGraphQL/api";
import { mutationShopifyDeliveryProfileCreate } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { pick } from "lodash";

import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

export async function createDeliveryProfile(
  deliveryProfileInput: DeliveryProfileInput,
  apolloClient: ApolloClient<object>
) {
  const res = await mutationShopifyDeliveryProfileCreate(
    pick(deliveryProfileInput, [
      "name",
      "locationGroupsToCreate",
      "sellingPlanGroupsToAssociate",
      "variantsToAssociate",
      "profileLocationGroups",
    ]),
    apolloClient
  );

  if (!res.body.data?.deliveryProfileCreate?.profile?.id) {
    throw new Error(`Couldn't get new profile id`);
    // In some cases, the request is stuck even after throw an exception, that's why we need the return
    // eslint-disable-next-line no-unreachable
    return;
  }

  const id = Number(shopifyGidToNumber(res.body.data.deliveryProfileCreate.profile.id));
  navigateWithShopInQuery(`${adminConfigRoutePrefix}/delivery-profiles/${id}`);
}
