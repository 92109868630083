import { LegacyStack, Modal, RadioButton, Text } from "@shopify/polaris";
import { LocationFragmentFragment } from "@smartrr/shared/shopifyGraphQL/api";
import { FullDeliveryProfileLocationGroup } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import React, { useCallback, useState } from "react";

export function AddLocationModal({
  open,
  location,
  profileLocationGroups,
  onClose,
  onSave,
}: {
  open: boolean;
  location: LocationFragmentFragment;
  profileLocationGroups: FullDeliveryProfileLocationGroup[];
  onClose(): void;
  onSave(profileLocationGroupIdOrNew: FullDeliveryProfileLocationGroup | "new"): void;
}): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<string>(
    profileLocationGroups.length ? profileLocationGroups[0].locationGroup.id : "new"
  );

  const onSaveClick = useCallback(() => {
    if (selectedOption === "new") {
      onSave(selectedOption);
    } else {
      const selectedGroup = profileLocationGroups.find(group => group.locationGroup.id === selectedOption)!;
      onSave(selectedGroup);
    }
  }, [selectedOption]);

  return (
    <Modal
      title={`Add rates for ${location.name}`}
      open={open}
      onClose={onClose}
      primaryAction={{
        content: "Done",
        onAction: onSaveClick,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack vertical spacing="extraTight">
            {!!profileLocationGroups.length && (
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                Use same rates as
              </Text>
            )}
            {profileLocationGroups.map(p => (
              <RadioButton
                key={p.locationGroup.id}
                checked={p.locationGroup.id === selectedOption}
                label={p.locationGroup.locations.length ? p.locationGroup.locations[0].name : "Unnamed group"}
                onChange={checked => {
                  if (checked) {
                    setSelectedOption(p.locationGroup.id);
                  }
                }}
                name="rates"
              />
            ))}
          </LegacyStack>
          <LegacyStack vertical spacing="extraTight">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              Create new rates
            </Text>
            <RadioButton
              label={`New rates for ${location.name}`}
              checked={selectedOption === "new"}
              onChange={checked => {
                if (checked) {
                  setSelectedOption("new");
                }
              }}
            />
          </LegacyStack>
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}
