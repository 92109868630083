import { Modal, TextField } from "@shopify/polaris";
import { FullDeliveryProfileLocationGroupZone } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { filterUndefined } from "@smartrr/shared/utils/filterUndefined";
import { flatten, groupBy } from "lodash";
import React, { useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { RegionList } from "./RegionList";
import { EncodedZoneId, IShopifyCountryServiceCountry, REST_OF_WORLD, Region } from "../../../../types";
import { decodeZoneId, getProvincesForCountryCode, useAllRegions } from "../../../../utils/zoneUtils";

export function CreateZoneModal({
  countries,
  onSave,
  onClose,
  open,
  zone,
}: {
  countries: IShopifyCountryServiceCountry[];
  open: boolean;
  onClose(): void;
  onSave(name: string, selectedItems: "restOfWorld" | Region[]): void;
  zone?: FullDeliveryProfileLocationGroupZone["zone"];
}): JSX.Element {
  const allRegions = useAllRegions(countries);

  const defaultIds = filterUndefined(
    zone?.countries.map(c => {
      const encodedCountry = allRegions.find(
        r => decodeZoneId(r.id).length === 2 && decodeZoneId(r.id)[1]! === c.code.countryCode
      )?.id;
      let encodedProvinces;
      if (!c.provinces.length) {
        encodedProvinces = allRegions.filter(
          r => decodeZoneId(r.id).length === 3 && decodeZoneId(r.id)[1] === c.code.countryCode
        );
      }
      encodedProvinces = filterUndefined(
        c.provinces.map(p => {
          return allRegions.find(r => {
            const decodedZone = decodeZoneId(r.id);
            return decodedZone.length === 3 && decodedZone[1] === c.code.countryCode && decodedZone[2] === p.code;
          })?.id;
        })
      );
      return filterUndefined([encodedCountry, ...encodedProvinces]);
    }) || []
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>(flatten(defaultIds));
  const [restOfWorldChecked, setRestOfWorldChecked] = useState(!!zone?.countries.find(c => !!c.code.restOfWorld));
  const [name, setName] = useState(zone?.name);
  const [nameError, setNameError] = useState<string>();
  const { addToast } = useToast();

  useEffect(() => {
    if (open) {
      setSelectedIds(flatten(defaultIds));
      setName(zone?.name);
    }
  }, [open]);

  const onSaveClick = () => {
    // shopify can derive name from selected countries if certain conditions are met,
    // but it's not worth my time to do that right now
    if (!name) {
      setNameError("Name is required.");
      return;
    }
    if (!selectedIds.length) {
      addToast("Select at least 1 country or region");
      return;
    }

    onSave(name, restOfWorldChecked ? REST_OF_WORLD : normalizeRegionsForSave(allRegions, selectedIds));

    setRestOfWorldChecked(false);
    setSelectedIds([]);
    setSearchQuery("");
    setName("");
    setNameError(undefined);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Create zone"
      primaryAction={{
        content: "Done",
        onAction: onSaveClick,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          autoComplete="off"
          label="Zone name"
          type="text"
          helpText="Customers won't see this."
          value={name}
          error={nameError}
          onChange={value => {
            setName(value);
            setNameError(undefined);
          }}
        />
      </Modal.Section>
      <Modal.Section>
        <TextField
          autoComplete="off"
          label=""
          labelHidden
          placeholder="Search countries and regions"
          type="text"
          onChange={setSearchQuery}
          value={searchQuery}
        />
      </Modal.Section>
      <Modal.Section>
        <RegionList
          selectedIds={selectedIds}
          restOfWorldChecked={restOfWorldChecked}
          countries={countries}
          searchQuery={searchQuery}
          onChange={(selectedIds, restOfWorldChecked) => {
            setSelectedIds(selectedIds);
            setRestOfWorldChecked(restOfWorldChecked);
          }}
        />
      </Modal.Section>
    </Modal>
  );
}

function normalizeRegionsForSave(allRegions: Region[], selectedIds: EncodedZoneId[]) {
  let normalizedSelectedIds: EncodedZoneId[] = [];

  const selectedIdsByContinentAndCountryCode = groupBy(selectedIds, id => {
    const decodedId = decodeZoneId(id);
    return decodedId.length === 1 ? null : `${decodedId[0]}_${decodedId[1]}`;
  });

  for (const [countryAndContinent, encodedCountryAndProvinceIds] of Object.entries(
    selectedIdsByContinentAndCountryCode
  )) {
    const [, countryCode] = decodeZoneId(countryAndContinent);
    const encodedProvinces = encodedCountryAndProvinceIds.filter(
      encodedId => decodeZoneId(encodedId).length !== 2
    );
    const decodedProvinceIds = encodedCountryAndProvinceIds
      .map(id => decodeZoneId(id))
      .filter(decodedId => decodedId.length !== 2);
    if (decodedProvinceIds.length === getProvincesForCountryCode(allRegions, countryCode!).length) {
      // if all the provinces of a country are selected, filter them out and just use the country
      normalizedSelectedIds.push(countryAndContinent);
    } else {
      normalizedSelectedIds = [...normalizedSelectedIds, countryAndContinent, ...encodedProvinces];
    }
  }

  return normalizedSelectedIds.map(id => ({
    id,
    label: allRegions.find(({ id: id2 }) => id2 === id)?.label || "",
  }));
}
