import { WeightUnit } from "@smartrr/shared/shopifyGraphQL/api";

export const getUnitWeightInputFromUnit = (unit: string | undefined): WeightUnit => {
  switch (unit) {
    case "lb": {
      return WeightUnit.Pounds;
    }
    case "oz": {
      return WeightUnit.Ounces;
    }
    case "kg": {
      return WeightUnit.Kilograms;
    }
    case "g": {
      return WeightUnit.Grams;
    }
    default: {
      return WeightUnit.Pounds;
    }
  }
};
