import { groupBy, isNil } from "lodash";
import { useMemo } from "react";

import { DecodedZoneId, EncodedZoneId, IShopifyCountryServiceCountry, Region } from "../types";

export function getProvincesForCountryCode(
  allRegions: Region[],
  countryCode: string | null | undefined
): Region[] {
  if (isNil(countryCode)) {
    return [];
  }
  return allRegions.filter(({ id }) => {
    const decodedId = decodeZoneId(id);
    return decodedId.length === 3 && decodedId[1] === countryCode;
  });
}

export function useAllRegions(countries: IShopifyCountryServiceCountry[]): Region[] {
  return useMemo(() => {
    const countriesByContinent = groupBy(countries, country => country.continent);
    return Object.keys(countriesByContinent).reduce((acc, continent) => {
      return acc.concat({ id: continent, label: continent }).concat(
        countriesByContinent[continent].reduce(
          (acc2, country) =>
            acc2.concat({ id: encodeZoneId(continent, country.code), label: country.name }).concat(
              country.zones.map(zone => ({
                id: encodeZoneId(continent, country.code, zone.code),
                label: zone.name,
              }))
            ),
          [] as { id: string; label: string }[]
        )
      );
    }, [] as Region[]);
  }, [countries]);
}

export function encodeZoneId(continent: string, country?: string, province?: string): EncodedZoneId {
  return [continent, country, province].filter(Boolean).join("_");
}

export function decodeZoneId(encodedZone: string): DecodedZoneId {
  return encodedZone.split("_") as [string, string?, string?];
}
