import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminDeliveryProfileRoute } from "@vendor-app/app/AdminRoute/AdminDeliveryProfilesRoute/AdminDeliveryProfileRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/configure/delivery-profiles/$deliveryProfileId")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const { deliveryProfileId } = Route.useParams();

  return AuthorizedRoute(
    FeatureEnum.CORE_CONFIGURATION,
    <AdminDeliveryProfileRoute deliveryProfileId={deliveryProfileId} />
  );
}
