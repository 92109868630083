import { ActionList, Button, DataTable, Icon, LegacyCard, Popover, Text } from "@shopify/polaris";
import { GlobeMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import {
  CountryCode,
  CurrencyCode,
  DeliveryConditionField,
  DeliveryConditionOperator,
} from "@smartrr/shared/shopifyGraphQL/api";
import { FullDeliveryProfileLocationGroupZone } from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { getLocale } from "@smartrr/shared/utils/getLocale";
import { flatten, groupBy } from "lodash";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";
import { v4 } from "uuid";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { CreateZoneModal } from "./modals/CreateZone/CreateZoneModal";
import { Condition, ConditionType, Rate, RateModal } from "./modals/RateModal";
import { IShopifyCountryServiceCountry, Region } from "../../types";
import { createMethodConditionsFromCondition } from "../../utils/formatUtils";
import { decodeZoneId, getProvincesForCountryCode, useAllRegions } from "../../utils/zoneUtils";

const ZoneNameAndRegionsContainer = styled(Box)`
  .Polaris-Icon {
    margin: 0;
  }
`;

interface Props {
  index: number;
  countries: IShopifyCountryServiceCountry[];
  zone: FullDeliveryProfileLocationGroupZone;
  onUpdate(updatedZone: FullDeliveryProfileLocationGroupZone): void;
  onRemove(): void;
}

export function Zone({ countries, zone: profileLocationGroupZone, onUpdate, onRemove }: Props): JSX.Element {
  const { zone, methodDefinitions } = profileLocationGroupZone;
  const [showRateModal, setShowRateModal] = useState<boolean | Rate>(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const unitWeight = useSmartrrVendorSelector(state => state.shopifyStoreData.shopifyData?.weight_unit);

  const allRegions = useAllRegions(countries);

  const rates = methodDefinitions.map(({ id, name, methodConditions, rateProvider }) => {
    const amount = rateProvider.__typename === "DeliveryRateDefinition" ? rateProvider.price.amount : null;
    const currency =
      rateProvider.__typename === "DeliveryRateDefinition" ? rateProvider.price.currencyCode : null;

    const minCondition = methodConditions.find(
      c => c.operator === DeliveryConditionOperator.GreaterThanOrEqualTo
    );
    const maxCondition = methodConditions.find(c => c.operator === DeliveryConditionOperator.LessThanOrEqualTo);

    const minConditionValue =
      minCondition?.field === DeliveryConditionField.TotalPrice
        ? (minCondition?.conditionCriteria as any).amount
        : minCondition?.field === DeliveryConditionField.TotalWeight
        ? (minCondition?.conditionCriteria as any).value
        : null;
    const maxConditionValue =
      maxCondition?.field === DeliveryConditionField.TotalPrice
        ? (maxCondition?.conditionCriteria as any).amount
        : maxCondition?.field === DeliveryConditionField.TotalWeight
        ? (maxCondition?.conditionCriteria as any).value
        : null;

    return {
      id,
      name,
      amount,
      currency,
      condition: methodConditions.length
        ? {
            type: (methodConditions[0].field === DeliveryConditionField.TotalPrice
              ? "price"
              : "weight") as ConditionType,
            ids: methodConditions.map(c => c.id),
            min: minConditionValue,
            max: maxConditionValue,
          }
        : undefined,
    };
  });

  const onSaveRate = useCallback(
    (id: string | null, name: string, amount: number, currencyCode: CurrencyCode, condition?: Condition) => {
      const formattedAmount = "" + amount;

      if (id) {
        const matchingDefIndex = methodDefinitions.findIndex(def => def.id === id);
        const matchingDef = methodDefinitions[matchingDefIndex];

        if (matchingDef?.rateProvider.__typename === "DeliveryRateDefinition") {
          onUpdate({
            ...profileLocationGroupZone,
            methodDefinitions: [
              ...methodDefinitions.slice(0, matchingDefIndex),
              {
                ...matchingDef,
                id,
                name,
                methodConditions: condition ? createMethodConditionsFromCondition(condition, currencyCode) : [],
                rateProvider: {
                  __typename: "DeliveryRateDefinition",
                  id: "",
                  price: {
                    amount: formattedAmount,
                    currencyCode,
                  },
                },
              },
              ...methodDefinitions.slice(matchingDefIndex + 1),
            ],
          });
        }
      } else {
        onUpdate({
          ...profileLocationGroupZone,
          methodDefinitions: [
            ...methodDefinitions,
            {
              // creating a dummy ID here because if a user creates a new rate and wants to edit it
              // prior to saving it, it will duplicate it if there is no ID to reference
              // The dummy ID is removed before saving in formatUtils
              id: `smartrr-${v4()}`,
              name,
              active: true,
              methodConditions: condition ? createMethodConditionsFromCondition(condition, currencyCode) : [],
              rateProvider: {
                __typename: "DeliveryRateDefinition",
                id: "",
                price: {
                  amount: formattedAmount,
                  currencyCode,
                },
              },
            },
          ],
        });
      }

      setShowRateModal(false);
    },
    [zone, methodDefinitions]
  );

  return (
    <LegacyCard.Section fullWidth>
      <CreateZoneModal
        zone={profileLocationGroupZone.zone}
        open={showZoneModal}
        countries={countries}
        onClose={() => setShowZoneModal(false)}
        onSave={(name: string, selectedZones: "restOfWorld" | Region[]) => {
          if (selectedZones === "restOfWorld") {
            onUpdate({
              ...profileLocationGroupZone,
              zone: {
                ...profileLocationGroupZone.zone,
                name,
                countries: [
                  {
                    code: {
                      restOfWorld: true,
                      countryCode: null,
                    },
                    id: "",
                    name: "Rest of World",
                    provinces: [],
                  },
                ],
              },
            });
            setShowZoneModal(false);
            return;
          }

          const selectedIdsByCountryCode = groupBy(selectedZones, ({ id }) => {
            const decodedId = decodeZoneId(id);
            return decodedId.length === 1 ? null : decodedId[1];
          });

          onUpdate({
            ...profileLocationGroupZone,
            zone: {
              ...profileLocationGroupZone.zone,
              name,
              countries: Object.keys(selectedIdsByCountryCode)
                .filter(code => code !== "null")
                .map(countryCode => {
                  const provinces = selectedIdsByCountryCode[countryCode].filter(
                    ({ id }) => decodeZoneId(id).length === 3
                  );

                  return {
                    id: "",
                    name: countries.find(c => c.code === countryCode)!.name,
                    code: {
                      countryCode: countryCode as CountryCode,
                      restOfWorld: false,
                    },
                    provinces: provinces.map(({ id: encodedId, label }) => ({
                      id: "",
                      name: label,
                      code: decodeZoneId(encodedId)[2]!,
                    })),
                  };
                }),
            },
          });
          setShowZoneModal(false);
        }}
      />
      <Box vertical mb={2} gap={1}>
        <Box justifyContent="space-between" gap={1}>
          <Box vertical>
            <ZoneNameAndRegionsContainer gap={1}>
              <div style={{ width: 20 }}>
                <Icon source={GlobeMajor} />
              </div>
              <Box vertical>
                <Text variant="bodyMd" as="span" fontWeight="semibold">
                  {zone.name}
                </Text>
                <Text variant="bodyMd" as="span" color="subdued">
                  {flatten(
                    zone.countries.map(
                      c =>
                        `${c.name}${c.provinces.length ? " " : ""}${
                          c.provinces.length && !includesAllProvinces(c, allRegions)
                            ? `(${c.provinces.map(p => p.name).join(", ")})`
                            : ""
                        }`
                    )
                  ).join(", ")}
                </Text>
              </Box>
            </ZoneNameAndRegionsContainer>
          </Box>
          <Box gap={1}>
            <Button plain onClick={() => setShowZoneModal(true)}>
              Edit
            </Button>
            <Button plain destructive onClick={onRemove}>
              Remove
            </Button>
          </Box>
        </Box>
        <Text variant="bodyMd" as="span" fontWeight="semibold">
          Rates
        </Text>

        <DataTable
          columnContentTypes={["text", "text", "numeric", "text", "text"]}
          headings={["Name", "Condition", "Price", "Currency", ""]}
          rows={rates.map(rate => {
            return [
              rate.name,
              getConditionDisplay(rate.condition, rate.currency, unitWeight),
              rate.amount === 0
                ? "Free"
                : Intl.NumberFormat(getLocale(), {
                    style: "currency",
                    currency: rate.currency || CurrencyCode.Usd,
                  }).format(rate.amount),
              rate.currency?.toUpperCase(),
              <MoreActionsButton
                key={rate.name}
                rate={rate}
                onEdit={rate => setShowRateModal(rate)}
                onDelete={rate => {
                  onUpdate({
                    ...profileLocationGroupZone,
                    methodDefinitions: methodDefinitions.filter(d => d.id !== rate.id),
                  });
                }}
              />,
            ];
          })}
        />

        <Box mt={3}>
          <Button onClick={() => setShowRateModal(true)}>Add rate</Button>
        </Box>
        <RateModal
          rate={typeof showRateModal === "boolean" ? undefined : showRateModal}
          show={!!showRateModal}
          onClose={() => setShowRateModal(false)}
          onSave={onSaveRate}
        />
      </Box>
    </LegacyCard.Section>
  );
}

function MoreActionsButton({
  rate,
  onEdit,
  onDelete,
}: {
  rate: Rate;
  onEdit(rate: Rate): void;
  onDelete(rate: Rate): void;
}) {
  const [isActive, setIsActive] = useState(false);
  const activator = (
    <Button size="slim" onClick={() => setIsActive(active => !active)} disclosure>
      Actions
    </Button>
  );

  const onEditClick = () => {
    onEdit(rate);
    setIsActive(false);
  };

  const onDeleteClick = () => {
    onDelete(rate);
    setIsActive(false);
  };

  return (
    <Popover activator={activator} active={isActive} onClose={() => setIsActive(active => !active)}>
      <ActionList
        items={[
          { content: "Edit", onAction: onEditClick },
          { content: "Delete", destructive: true, onAction: onDeleteClick },
        ]}
      />
    </Popover>
  );
}

function getConditionDisplay(
  condition: Condition | undefined,
  currency: CurrencyCode | null,
  unitWeight?: string
) {
  if (!condition) {
    return "--";
  }

  const { min, max, type } = condition;
  if (type === "price") {
    const currencyFormatter = Intl.NumberFormat(getLocale(), {
      style: "currency",
      currency: currency || CurrencyCode.Usd,
    });
    return `${currencyFormatter.format(min)} - ${max ? currencyFormatter.format(max) : "No limit"}`;
  }
  return `${min}${unitWeight ?? "-"} - ${max ? `${max}${unitWeight ?? "-"}` : "No limit"}`;
}

function includesAllProvinces(
  c: DeepExtractTypeSkipArrays<FullDeliveryProfileLocationGroupZone, ["zone", "countries"]>,
  allRegions: Region[]
) {
  const allProvincesForCountry = getProvincesForCountryCode(allRegions, c.code.countryCode);
  return allProvincesForCountry.every(p =>
    c.provinces.find(countryProvince => countryProvince.code === decodeZoneId(p.id)[2])
  );
}
