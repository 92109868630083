export const validateIfNumberAboveZero = (value: any, key: string) => {
  const number = Number(value);
  if (!Number.isFinite(number)) {
    return `${key} should be a number`;
  }
  if (number < 0) {
    return `${key} should be greater than zero\n`;
  }
  return "";
};

export const validateIfFirstLowerThanSecondValue = (
  firstValue: string | number,
  secondValue: string | number,
  key1: string,
  key2: string
) => {
  if (!Number(firstValue) || !Number(secondValue)) {
    return "";
  }
  if (Number(secondValue) > Number(firstValue)) {
    return `${key1} should be greater than ${key2}`;
  }
  return "";
};

export const returnFilteredArrayIfItExists = <T>(array: T[], filterFunction: (item: T) => boolean) => {
  const filteredArray = array.filter(filterFunction);
  return filteredArray.length ? filteredArray : undefined;
};
