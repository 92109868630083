import { ApolloClient } from "@apollo/client";

import {
  paginatedQueryShopifyDeliveryProfileItems,
  paginatedQueryShopifyDeliveryProfileLocationGroupLocations,
  paginatedQueryShopifyDeliveryProfileLocationGroupZoneMethodDefinitions,
  paginatedQueryShopifyDeliveryProfileLocationGroupZones,
  paginatedQueryShopifyDeliveryProfileSellingPlanGroups,
  queryShopifyDeliveryProfile,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";

export async function loadFullDeliveryProfile(id: number | string, apolloClient: ApolloClient<object>) {
  const deliveryProfileId = ensureShopifyGid("DeliveryProfile", id);

  const deliveryProfileRes = await queryShopifyDeliveryProfile(deliveryProfileId, apolloClient);
  if (deliveryProfileRes.type === "error" || !deliveryProfileRes.body.data.deliveryProfile) {
    throw new Error(`Error loading delivery profile ${id}`);
  }

  const deliveryProfile = deliveryProfileRes.body.data.deliveryProfile;

  const profileLocationGroupZones = await Promise.all(
    deliveryProfile.profileLocationGroups.map(async (locationGroup, index) => {
      return paginatedQueryShopifyDeliveryProfileLocationGroupZones(apolloClient, deliveryProfileId, index);
    })
  );

  const profileLocationGroupMethodDefinitionsPromise = Promise.all(
    profileLocationGroupZones.map(async (zones, index) => {
      if (!zones.length) {
        return [];
      }

      const counts = zones.map(
        zone =>
          zone.methodDefinitionCounts.participantDefinitionsCount +
          zone.methodDefinitionCounts.rateDefinitionsCount
      );
      return paginatedQueryShopifyDeliveryProfileLocationGroupZoneMethodDefinitions(
        apolloClient,
        deliveryProfileId,
        index,
        counts
      );
    })
  );

  const profileSellingPlanGroupsPromise = paginatedQueryShopifyDeliveryProfileSellingPlanGroups(
    deliveryProfileId,
    apolloClient
  );
  const profileItemsPromise = paginatedQueryShopifyDeliveryProfileItems(deliveryProfileId, apolloClient);

  const profileLocationGroupLocationsPromise = Promise.all(
    deliveryProfile.profileLocationGroups.map(async (locationGroup, index) => {
      return paginatedQueryShopifyDeliveryProfileLocationGroupLocations(apolloClient, deliveryProfileId, index);
    })
  );

  const [
    profileLocationGroupLocations,
    profileLocationGroupMethodDefinitions,
    profileSellingPlanGroups,
    profileItems,
  ] = await Promise.all([
    profileLocationGroupLocationsPromise,
    profileLocationGroupMethodDefinitionsPromise,
    profileSellingPlanGroupsPromise,
    profileItemsPromise,
  ]);

  return {
    ...deliveryProfile,
    sellingPlanGroups: profileSellingPlanGroups,
    profileItems,
    profileLocationGroups: deliveryProfile.profileLocationGroups.map((locationGroup, groupIndex) => ({
      ...locationGroup,
      locationGroup: {
        ...locationGroup.locationGroup,
        locations: profileLocationGroupLocations[groupIndex],
      },
      locationGroupZones: profileLocationGroupZones[groupIndex].map((zone, zoneIndex) => ({
        ...zone,
        methodDefinitions: profileLocationGroupMethodDefinitions[groupIndex][zoneIndex],
      })),
    })),
  };
}

type Awaited<P> = P extends Promise<infer U> ? U : P;
export type FullDeliveryProfile = Awaited<ReturnType<typeof loadFullDeliveryProfile>>;
