import { Banner, Modal, Text } from "@shopify/polaris";
import { GetSellingPlanGroupsQuery } from "@smartrr/shared/shopifyGraphQL/api";
import React from "react";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

type SellingPlanGroupNode = DeepExtractTypeSkipArrays<
  GetSellingPlanGroupsQuery,
  ["sellingPlanGroups", "edges", "node"]
>;

const HandleSingularConflict = ({
  conflictingSellingPlanGroup,
}: {
  conflictingSellingPlanGroup: SellingPlanGroupNode;
}) => {
  return (
    <React.Fragment>
      <Text as="p" variant="bodyMd">
        There is a shipping profile attached to <b>{conflictingSellingPlanGroup.name}</b> program.
      </Text>
      <Text as="p" variant="bodyMd">
        The program will be detached from the old shipping profile and all subscriptions will use the newly
        created shipping profile.
      </Text>
    </React.Fragment>
  );
};

interface ConflictTextProps {
  group: SellingPlanGroupNode;
  index: number;
  length: number;
}
const ConflictText = ({ group, index, length }: ConflictTextProps) => {
  return (
    <React.Fragment key={index}>
      {index === length - 1 ? <span>and&nbsp;</span> : <React.Fragment></React.Fragment>}
      <b>{group.name}</b>
      {index === length - 1 ? <React.Fragment></React.Fragment> : <span>,&nbsp;</span>}
    </React.Fragment>
  );
};

const HandleMultipleConflicts = ({
  conflictingSellingPlanGroups,
}: {
  conflictingSellingPlanGroups: SellingPlanGroupNode[];
}) => {
  return (
    <React.Fragment>
      <Text as="p" variant="bodyMd">
        There are shipping profile(s) attached to&nbsp;
        {conflictingSellingPlanGroups.map((group, index) => (
          <ConflictText group={group} index={index} length={conflictingSellingPlanGroups.length} />
        ))}
        &nbsp; programs.
      </Text>
      <Text as="p" variant="bodyMd">
        The programs will be detached from the old shipping profile(s) and all subscriptions will use the newly
        created shipping profile.
      </Text>
    </React.Fragment>
  );
};

interface ConflictingWarningModalProps {
  onConfirm: () => void;
  onClose: () => void;
  conflictingSellingPlanGroups: SellingPlanGroupNode[];
}

export const ConflictWarningModal = ({
  onClose,
  onConfirm,
  conflictingSellingPlanGroups,
}: ConflictingWarningModalProps) => {
  return (
    <Modal
      open={!!conflictingSellingPlanGroups.length}
      title={"Warning"}
      onClose={onClose}
      primaryAction={{
        content: "Confirm",
        onAction() {
          onConfirm();
          onClose();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Banner status="info">
          {conflictingSellingPlanGroups.length === 1 ? (
            <HandleSingularConflict conflictingSellingPlanGroup={conflictingSellingPlanGroups[0]} />
          ) : (
            <HandleMultipleConflicts conflictingSellingPlanGroups={conflictingSellingPlanGroups} />
          )}
        </Banner>
      </Modal.Section>
    </Modal>
  );
};
