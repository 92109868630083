import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import {
  DeliveryConditionField,
  DeliveryConditionOperator,
  DeliveryCountryInput,
  DeliveryLocationGroupZoneInput,
  DeliveryMethodDefinitionFragmentFragment,
  DeliveryMethodDefinitionInput,
  DeliveryProfileInput,
  LocationFragmentFragment,
  MoneyV2,
  CurrencyCode as ShopifyCurrencyCode,
  Weight,
  WeightUnit,
} from "@smartrr/shared/shopifyGraphQL/api";
import {
  FullDeliveryProfile,
  FullDeliveryProfileLocationGroup,
  FullDeliveryProfileLocationGroupZone,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";
import { flatten, flattenDeep } from "lodash";

import { Condition } from "../AdminDeliveryProfileRoute/components/modals/RateModal";

export function fullDeliveryProfileToDeliveryProfileInput(
  currentDeliveryProfile: FullDeliveryProfile,
  newDeliveryProfile: FullDeliveryProfile,
  selectedSellingPlanGroupIds: string[],
  selectedProductIds: string[],
  purchasables: IPurchasable[]
) {
  const currentDeliveryProfileMethodDefinitionIds = flattenDeep(
    currentDeliveryProfile.profileLocationGroups.map(group =>
      (group as FullDeliveryProfileLocationGroup).locationGroupZones.map(zone =>
        zone.methodDefinitions.map(d => d.id)
      )
    )
  );
  const newDeliveryProfileMethodDefinitionIds = flattenDeep(
    newDeliveryProfile.profileLocationGroups.map(group =>
      (group as FullDeliveryProfileLocationGroup).locationGroupZones.map(zone =>
        zone.methodDefinitions.map(d => d.id)
      )
    )
  );

  const selectedVariants = flatten(
    purchasables
      .filter(
        purchasable =>
          !currentDeliveryProfile.profileItems.find(p => p.product.id === purchasable.shopifyId!) &&
          selectedProductIds.includes(purchasable.shopifyId!)
      )
      .map(p => (p.vnts || []).map(v => v.shopifyId!))
  );
  const deselectedVariants = flatten(
    purchasables
      .filter(
        purchasable =>
          currentDeliveryProfile.profileItems.find(p => p.product.id === purchasable.shopifyId!) &&
          !selectedProductIds.includes(purchasable.shopifyId!)
      )
      .map(p => (p.vnts || []).map(v => v.shopifyId!))
  );

  const currentDeliveryProfileConditionIds = flattenDeep(
    currentDeliveryProfile.profileLocationGroups.map(group =>
      (group as FullDeliveryProfileLocationGroup).locationGroupZones.map(zone =>
        zone.methodDefinitions.map(def => def.methodConditions.filter(c => !!c.id).map(c => c.id))
      )
    )
  );
  const newDeliveryProfileConditionIds = flattenDeep(
    newDeliveryProfile.profileLocationGroups.map(group =>
      (group as FullDeliveryProfileLocationGroup).locationGroupZones.map(zone =>
        zone.methodDefinitions.map(def => def.methodConditions.filter(c => !!c.id).map(c => c.id))
      )
    )
  );
  const conditionsToDelete = currentDeliveryProfileConditionIds.filter(
    id => !newDeliveryProfileConditionIds.includes(id)
  );

  const deliveryProfileInput: DeliveryProfileInput = {
    name: newDeliveryProfile.name,
    methodDefinitionsToDelete: currentDeliveryProfileMethodDefinitionIds.filter(
      id => !newDeliveryProfileMethodDefinitionIds.includes(id)
    ),
    sellingPlanGroupsToAssociate: selectedSellingPlanGroupIds.filter(
      id => !currentDeliveryProfile.sellingPlanGroups.find(g => g.id === id)
    ),
    sellingPlanGroupsToDissociate: currentDeliveryProfile.sellingPlanGroups
      .map(g => g.id)
      .filter(id => !selectedSellingPlanGroupIds.includes(id)),
    locationGroupsToDelete: [],
    locationGroupsToCreate: [],
    locationGroupsToUpdate: [],
    variantsToAssociate: selectedVariants,
    variantsToDissociate: deselectedVariants,
    conditionsToDelete,
  };

  for (const profileLocationGroup of newDeliveryProfile.profileLocationGroups) {
    const { locationGroup, locationGroupZones } = profileLocationGroup as FullDeliveryProfileLocationGroup;

    if (!locationGroup.id || locationGroup.id.startsWith("new")) {
      deliveryProfileInput.locationGroupsToCreate?.push({
        locations: locationGroup.locations.map(loc => loc.id),
        zonesToCreate: locationGroupZones
          .filter(({ zone }) => !zone.id)
          .map(fullDeliveryProfileLocationGroupZoneToZoneInput),
        zonesToUpdate: locationGroupZones
          .filter(zone => !!zone.zone.id)
          .map(fullDeliveryProfileLocationGroupZoneToZoneInput),
      });
    } else {
      deliveryProfileInput.locationGroupsToUpdate?.push({
        id: locationGroup.id,
        locations: locationGroup.locations.map(loc => loc.id),
        zonesToCreate: locationGroupZones
          .filter(zone => !zone.zone.id)
          .map(fullDeliveryProfileLocationGroupZoneToZoneInput),
        zonesToUpdate: locationGroupZones
          .filter(zone => !!zone.zone.id)
          .map(fullDeliveryProfileLocationGroupZoneToZoneInput),
      });

      const currentZones = (
        currentDeliveryProfile.profileLocationGroups.find(
          group => group.locationGroup.id === locationGroup.id
        ) as FullDeliveryProfileLocationGroup
      ).locationGroupZones;
      const locationGroupZonesToDelete = currentZones.filter(
        currentZone => !locationGroupZones.find(({ zone }) => zone.id === currentZone.zone.id)
      );
      deliveryProfileInput.zonesToDelete = locationGroupZonesToDelete.length
        ? (deliveryProfileInput.zonesToDelete || []).concat(locationGroupZonesToDelete.map(({ zone }) => zone.id))
        : [];
    }
  }

  for (const profileDeliveryGroup of currentDeliveryProfile.profileLocationGroups) {
    if (
      !newDeliveryProfile.profileLocationGroups.find(
        g => profileDeliveryGroup.locationGroup.id === g.locationGroup.id
      )
    ) {
      deliveryProfileInput.locationGroupsToDelete?.push(profileDeliveryGroup.locationGroup.id);
    }
  }

  for (const group of newDeliveryProfile.sellingPlanGroups) {
    if (!currentDeliveryProfile.sellingPlanGroups.find(g => g.id === group.id)) {
      deliveryProfileInput.sellingPlanGroupsToAssociate?.push(group.id);
    }
  }

  for (const group of currentDeliveryProfile.sellingPlanGroups) {
    if (!newDeliveryProfile.sellingPlanGroups.find(g => g.id === group.id)) {
      deliveryProfileInput.sellingPlanGroupsToDissociate?.push(group.id);
    }
  }

  return deliveryProfileInput;
}

function fullDeliveryProfileLocationGroupZoneToZoneInput(
  locationGroupZone: FullDeliveryProfileLocationGroupZone
): DeliveryLocationGroupZoneInput {
  const { zone, methodDefinitions } = locationGroupZone;

  const countries = zone.countries
    .map(({ id, code, provinces }) => {
      return {
        code: code.countryCode,
        provinces: provinces.map(p => ({ code: p.code })),
        includeAllProvinces: !provinces.length,
        restOfWorld: code.restOfWorld,
      } as DeliveryCountryInput;
    })
    .filter((x): x is DeliveryCountryInput => !!x);

  const input: DeliveryLocationGroupZoneInput = {
    id: zone.id || undefined,
    name: zone.name,
    methodDefinitionsToCreate: methodDefinitions
      .filter(d => !d.id || d.id.includes("smartrr"))
      .map(methodDefinitionToMethodDefinitionInput),
    methodDefinitionsToUpdate: methodDefinitions
      .filter(d => d.id && !d.id.includes("smartrr"))
      .map(methodDefinitionToMethodDefinitionInput),
  };

  if (countries.length) {
    input.countries = countries;
  }

  return input;
}

function methodDefinitionToMethodDefinitionInput(
  methodDefinition: FullDeliveryProfileLocationGroupZone["methodDefinitions"][number]
): DeliveryMethodDefinitionInput {
  const { id, name, active, rateProvider, methodConditions } = methodDefinition;
  return {
    id: id.includes("smartrr-") ? undefined : id || undefined,
    name,
    description: name,
    active,
    priceConditionsToCreate: methodConditions
      .filter(c => c.field === "TOTAL_PRICE" && !c.id)
      .map(c => ({
        criteria: {
          amount: (c.conditionCriteria as MoneyV2).amount,
          currencyCode: (c.conditionCriteria as MoneyV2).currencyCode,
        },
        operator: c.operator,
      })),
    weightConditionsToCreate: methodConditions
      .filter(c => c.field === "TOTAL_WEIGHT" && !c.id)
      .map(c => ({
        criteria: {
          unit: (c.conditionCriteria as Weight).unit,
          value: (c.conditionCriteria as Weight).value,
        },
        operator: c.operator,
      })),
    rateDefinition: {
      id: rateProvider.id || undefined,
      price:
        rateProvider.__typename === "DeliveryRateDefinition"
          ? {
              amount: "" + rateProvider.price.amount,
              currencyCode: rateProvider.price.currencyCode,
            }
          : {
              amount: "" + 0,
              currencyCode: ShopifyCurrencyCode.Usd,
            },
    },
  };
}

export function addLocationGroupToFullDeliveryProfile(
  deliveryProfile: FullDeliveryProfile,
  location: LocationFragmentFragment
): FullDeliveryProfile {
  return {
    ...deliveryProfile,
    unassignedLocations: (deliveryProfile.unassignedLocations || []).filter(loc => loc.id !== location.id),
    profileLocationGroups: [
      ...deliveryProfile.profileLocationGroups,
      {
        locationGroupZones: [],
        countriesInAnyZone: [],
        locationGroup: {
          id: `new-${location.name}`,
          locations: [location],
        },
      },
    ],
  };
}

export function addLocationToProfileLocationGroup(
  deliveryProfile: FullDeliveryProfile,
  profileLocationGroupToUpdate: FullDeliveryProfileLocationGroup,
  location: LocationFragmentFragment
): FullDeliveryProfile {
  return {
    ...deliveryProfile,
    unassignedLocations: deliveryProfile.unassignedLocations.filter(loc => loc.id !== location.id),
    profileLocationGroups: updateMatchInArray(
      deliveryProfile.profileLocationGroups as FullDeliveryProfileLocationGroup[],
      {
        ...profileLocationGroupToUpdate,
        locationGroup: {
          ...profileLocationGroupToUpdate.locationGroup,
          locations: [...profileLocationGroupToUpdate.locationGroup.locations, location],
        },
      },
      ({ locationGroup }: FullDeliveryProfileLocationGroup) => locationGroup.id
    ),
  };
}

export function createMethodConditionsFromCondition(
  condition: Condition,
  currencyCode: CurrencyCode
): DeliveryMethodDefinitionFragmentFragment["methodConditions"] {
  const conditions: DeliveryMethodDefinitionFragmentFragment["methodConditions"] = [
    {
      id: "",
      field: condition.type === "price" ? DeliveryConditionField.TotalPrice : DeliveryConditionField.TotalWeight,
      operator: DeliveryConditionOperator.GreaterThanOrEqualTo,
      conditionCriteria:
        condition.type === "price"
          ? { currencyCode: currencyCode as ShopifyCurrencyCode, amount: "" + condition.min }
          : { unit: condition?.unit ?? WeightUnit.Pounds, value: condition.min },
    },
  ];

  if (condition.max !== null) {
    conditions.push({
      id: "",
      field: condition.type === "price" ? DeliveryConditionField.TotalPrice : DeliveryConditionField.TotalWeight,
      operator: DeliveryConditionOperator.LessThanOrEqualTo,
      conditionCriteria:
        condition.type === "price"
          ? { currencyCode: currencyCode as ShopifyCurrencyCode, amount: "" + condition.max }
          : { unit: condition?.unit ?? WeightUnit.Pounds, value: condition.max },
    });
  }

  return conditions;
}

export function hasAnySellingPlanGroupsChanges(
  deliveryProfile: FullDeliveryProfile | undefined,
  selectedSellingPlanGroupIds: string[]
) {
  return (
    deliveryProfile &&
    JSON.stringify(deliveryProfile?.sellingPlanGroups.map(g => g.id).sort()) !==
      JSON.stringify(selectedSellingPlanGroupIds.sort())
  );
}

export function hasAnyProductChanges(
  deliveryProfile: FullDeliveryProfile | undefined,
  selectedProductIds: string[]
) {
  return (
    deliveryProfile &&
    JSON.stringify(deliveryProfile?.profileItems.map(p => p.product.id).sort()) !==
      JSON.stringify(selectedProductIds.sort())
  );
}
