export type EncodedZoneId = string;
export type DecodedZoneId = [string, string?, string?];

export interface IShopifyCountryServiceCountry {
  autocompletionField: string;
  code: string;
  continent: string;
  formatting: {
    edit: string;
    show: string;
  };
  labels: {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    firstName: string;
    lastName: string;
    phone: string;
    postalCode: string;
    zone: string;
  };
  name: string;
  phoneNumberPrefix: number;
  provinceKey: string;
  zones: IShopifyCountryServiceZone[];
}

export interface IShopifyCountryServiceZone {
  code: string;
  name: string;
}

// continent, country, or zone
export interface Region {
  id: EncodedZoneId;
  label: string;
}

export const REST_OF_WORLD = "restOfWorld";
